import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth/slice';
import checkoutOrderReducer from './orders/checkoutSlice';
import paidOrderReducer from './orders/paidSlice';
import ongoingOrderReducer from './orders/ongoingSlice';
import finishedOrderReducer from './orders/finishedSlice';
import detailOrderReducer from './orders/detailOrderSlice';
import brandsReducer from './brands/slice';
import vehicleReducer from './vehicle/vehicleSlice';
import garageReducer from './garages/slice';
import citiesReducer from './cities/slice';
import userReducer from './user/slice';
import notifReducer from './notification/notifSlice';
import notificationInboxReducer from './notification-inbox/notificationInboxSlice';
import depositReducer from './orders/depositSlice';
import banksReducer from './banks/slice';
import businessRulesReducer from './business-rules/slice';
import deliveryLocationReducer from './delivery-location/slice';
import rentalLocationReducer from './rental-location/slice';
import contactUsReducer from './contact-us/slice';
import aboutUsReducer from './about-us/slice';
import cancelledOrderReducer from './orders/cancelledOrderSlice';
import invoiceReducer from './invoice/slice';
import bannerReducer from './banner/slice';
import createOrderReducer from './orders/createOrder';
import summaryOrderReducer from './orders/summaryOrderSlice';
import paymentReducer from './payment/slice';
import createDisbursementsReducer from './orders/createDisbursementsSlice';
import reconfirmationOrderReducer from './orders/reconfirmationSlice';
import rolesReducer from './roles/slice';
import permissionsReducer from './permissions/slice';
import categoryReducer from './category/categorySlice';
import violationsReducer from './violations/violationsSlice';
import promoReducer from './promo/promoSlice';
import schedulePriceReducer from './schedule-price/schedulePriceSlice';
import schedulePriceValueReducer from './schedule-price/schedulePriceValue';
import withDriverOrderReducer from './orders/withDriverOrderSlice';
import withoutDriverOrderReducer from './orders/withoutDriverOrderSlice';
import couriersReducer from './couriers/slice';
import courierTaskReducer from './couriers/courierTaskSlice';
import zoneReducer from './zone/zoneSlice';
import airportTransferOrderReducer from './orders/airportTransferOrderSlice';
import shuttleAirportReducer from './shuttle-airport/slice';
import vehicleAirportPackageReducer from './vehicle/vehicleAirportPackageSlice';
import orderDetailReducer from './order-detail-pdf/slice';
import additionalsReducer from './additionals/additionalsSlice';
import userStatsReducer from './user/userStatsSlice';
import trxMonthToMonthReducer from './report/trxMonthToMonthSlice';
import allRevenueReducer from './revenue/allRevenueSlice';
import withDriverRevenueSlice from './revenue/withDriverRevenueSlice';
import withoutDriverRevenueSlice from './revenue/withoutDriverRevenueSlice';
import airportTransferRevenueSlice from './revenue/airportTransferRevenueSlice';
import eligibleVehicleReducer from './vehicle/eligibleVehicleSlice';
import totalDepositReducer from './deposit/totalDepositSlice';
import totalDepositWithDriverReducer from './deposit/totalDepositWithDriverSlice';
import totalDepositWithoutDriverReducer from './deposit/totalDepositWithoutDriverSlice';
import totalDepositAirportReducer from './deposit/totalDepositAirportSlice';
import servicesReducer from './services/slice';
import approvalsReducer from './approvals/slice';
import subserviceReducer from './subservices/subservicesSlice';
import languageReducer from './language/slice';
import termAndConditionReducer from './term-and-condition/termAndConditionSlice';
import overtimeReducer from './overtime/slice';
import matrixReducer from './matrix/slice';
import employeesReducer from './employee/slice';
import menuReducer from './menu/menuSlice';
import airportLocationTypeReducer from './airport-location-type/slice';
import airportLocationReducer from './airport-location/slice';
import airportPackagesReducer from './airport-packages/slice';
import userInfluencerReducer from './user/userInfluencerSlice';
import addonReducer from './addon/slice';
import orderAddonReducer from './addon/orderAddonsSlice';

const reducers = combineReducers({
  aboutUs: aboutUsReducer,
  additionals: additionalsReducer,
  addon: addonReducer,
  airportLocation: airportLocationReducer,
  airportLocationType: airportLocationTypeReducer,
  airportPackages: airportPackagesReducer,
  airportTransferOrder: airportTransferOrderReducer,
  airportTransferRevenue: airportTransferRevenueSlice,
  allRevenue: allRevenueReducer,
  approvals: approvalsReducer,
  auth: authReducer,
  banner: bannerReducer,
  banks: banksReducer,
  brands: brandsReducer,
  businessRules: businessRulesReducer,
  cancelledOrder: cancelledOrderReducer,
  category: categoryReducer,
  checkoutOrder: checkoutOrderReducer,
  cities: citiesReducer,
  couriers: couriersReducer,
  courierTask: courierTaskReducer,
  createDisbursements: createDisbursementsReducer,
  createOrder: createOrderReducer,
  depositOrder: depositReducer,
  contactUs: contactUsReducer,
  detailOrder: detailOrderReducer,
  eligibleVehicle: eligibleVehicleReducer,
  employees: employeesReducer,
  finishedOrder: finishedOrderReducer,
  garages: garageReducer,
  invoice: invoiceReducer,
  language: languageReducer,
  deliveryLocation: deliveryLocationReducer,
  matrix: matrixReducer,
  menu: menuReducer,
  notification: notifReducer,
  notificationInbox: notificationInboxReducer,
  ongoingOrder: ongoingOrderReducer,
  orderDetail: orderDetailReducer,
  orderAddon: orderAddonReducer,
  overtime: overtimeReducer,
  paidOrder: paidOrderReducer,
  payment: paymentReducer,
  permissions: permissionsReducer,
  reconfirmationOrder: reconfirmationOrderReducer,
  promo: promoReducer,
  rentalLocation: rentalLocationReducer,
  services: servicesReducer,
  shuttleAirport: shuttleAirportReducer,
  schedulePrice: schedulePriceReducer,
  schedulePriceValue: schedulePriceValueReducer,
  subservices: subserviceReducer,
  roles: rolesReducer,
  summaryOrder: summaryOrderReducer,
  termAndCondition: termAndConditionReducer,
  totalDeposit: totalDepositReducer,
  totalDepositAirport: totalDepositAirportReducer,
  totalDepositWithDriver: totalDepositWithDriverReducer,
  totalDepositWithoutDriver: totalDepositWithoutDriverReducer,
  trxMonthToMonth: trxMonthToMonthReducer,
  user: userReducer,
  userInfluencer: userInfluencerReducer,
  userStats: userStatsReducer,
  vehicle: vehicleReducer,
  vehicleAirportPackage: vehicleAirportPackageReducer,
  violations: violationsReducer,
  withDriverOrder: withDriverOrderReducer,
  withoutDriverOrder: withoutDriverOrderReducer,
  withDriverRevenue: withDriverRevenueSlice,
  withoutDriverRevenue: withoutDriverRevenueSlice,
  zone: zoneReducer,
});

const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      // ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    }),
});

export default store;
