import { refreshToken } from 'features/auth/actions';
import { rehydrateToken } from 'features/auth/slice';
import { createContext, useCallback, useContext, useEffect, useId, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { formatDistanceToNowStrict, isFuture } from 'date-fns';
import { getAdminProfile } from 'features/user/actions';
import { checkEmptyObject } from 'utils/functionality';
import { getRolePermissionsById } from 'features/permissions/actions';
import { setSelectedMenu } from 'features/menu/menuSlice';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const userProfile = useSelector((state) => state.user.profile);
  // Modals
  const [showSpinner, setShowSpinner] = useState(false);
  const [showTransactionDetail, setShowTransactionDetail] = useState(false);
  const [showSendNotification, setShowSendNotification] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState({
    show: false,
    message: '',
    onClick: () => {},
    variant: 'primary',
    valueButton: 'Iya, Lanjutkan',
  });
  const [showBlockConfirmation, setShowBlockConfirmation] = useState({
    show: false,
    message: '',
    onClick: () => {},
  });
  const [showAddCar, setShowAddCar] = useState(false);
  const [showAddTourSchedule, setShowAddTourSchedule] = useState(false);
  const [showAddNewGarage, setShowAddNewGarage] = useState(false);
  const [showAddNewCity, setShowAddNewCity] = useState(false);
  const [toastList, setToastList] = useState([]);
  const [showRefundDetail, setShowRefundDetail] = useState(false);
  const [showNotificationForm, setShowNotificationForm] = useState(false);
  const [showNotificationTemplate, setShowNotificationTemplate] = useState(false);
  const [showNotificationDetail, setShowNotificationDetail] = useState('list');
  const [showCityDeleteConfirmation, setShowCityDeleteConfirmation] = useState(false);
  const [showDepositDetailModal, setShowDepositDetailModal] = useState({
    show: false,
    history: false,
  });
  const [showDepositConfirmation, setShowDepositConfirmation] = useState(false);
  // const [active, setActive] = useState('Dashboard');
  // const [activeSubMenu, setActiveSubMenu] = useState('Dashboard');
  const toastId = useId();
  const [showAddDeliveryLocation, setShowAddDeliveryLocation] = useState({
    show: false,
    method: 'add',
  });
  const [showContactUsDetail, setShowContactUsDetail] = useState(false);
  const [showAddAboutUsImage, setShowAddAboutUsImage] = useState(false);
  const [showAddBannerModal, setShowAddBannerModal] = useState(false);
  const [showAddBrandCar, setShowAddBrandCar] = useState(false);
  const [cancelPaymentMessageValue, setCancelPaymentMessageValue] = useState('');
  const [showCancelPaymentMessage, setShowCancelPaymentMessage] = useState(false);
  const [showAddRolesModal, setShowAddRolesModal] = useState(false);
  const [showAddRolesAssignModal, setShowAddRolesAssignModal] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showAddViolationsModal, setShowAddViolationsModal] = useState(false);
  const [showAssignDriver, setShowAssignDriver] = useState('');
  const [showZonePrice, setShowZonePrice] = useState(false);
  const [showRejectOrderModal, setShowRejectOrderModal] = useState(false);
  const [showAddAirportLocationType, setShowAddAirportLocationType] = useState(false);
  const [showAddAirportLocation, setShowAddAirportLocation] = useState(false);
  // const [isDummyModal, setIsDummyModal] = useState(false);
  // const [showStoresReady, setShowStoresReady] = useState(false);
  // const [exploreTemplate, setExploreTemplate] = useState(false);
  const [showAddNewLanguage, setShowAddNewLanguage] = useState(false);
  const [showAdditionalImageModal, setShowAdditionalImageModal] = useState(false);

  const dispatch = useDispatch();

  const showToast = useCallback(
    (payload) => {
      if (toastList[toastList.length - 1]?.payload.message === payload.message) return;
      setToastList((toasts) => [
        ...toasts,
        {
          id: toastId + Math.random().toPrecision(6).toString(),
          payload,
        },
      ]);
    },
    [toastId, toastList],
  );

  // rehydrate token from cookies to redux state once web loaded
  useEffect(() => {
    dispatch(rehydrateToken());
  }, [dispatch]);

  useEffect(() => {
    if (!auth.isLoggedIn) return;
    dispatch(getAdminProfile());
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (checkEmptyObject(userProfile)) return;
    dispatch(getRolePermissionsById(userProfile.role_id)).then((res) => dispatch(setSelectedMenu(res.payload)));
  }, [userProfile]);

  // refreshing token every 14 minutes
  // const periodicalRefreshingToken = useCallback(
  //   () => {
  //     const accessToken = auth.data.access_token;
  //     const refresh_token = auth.data.refresh_token;

  //     if (!refresh_token) return;

  //     if (accessToken) {
  //       const { exp } = jwt_decode(accessToken);
  //       const expires = new Date(exp * 1000);

  //       if (isFuture(expires)) {
  //         const res = formatDistanceToNowStrict(expires, {
  //           unit: 'second',
  //         });
  //         const secondsLeft = parseInt(res.split(' ')[0]) - 10;

  //         setInterval(() => {
  //           dispatch(refreshToken());
  //         }, secondsLeft * 1000);
  //       } else {
  //         dispatch(refreshToken());
  //       }
  //     }
  //   },
  //   [dispatch, auth.data],
  // );

  // refreshing token every 14 minutes
  useEffect(() => {
    if (!auth.isLoggedIn) return;

    const accessToken = auth.data.access_token;
    const refresh_token = auth.data.refresh_token;

    if (!refresh_token) return;

    let refreshInterval;

    if (accessToken) {
      const { exp } = jwt_decode(accessToken);
      const expires = new Date(exp * 1000);

      if (isFuture(expires)) {
        const res = formatDistanceToNowStrict(expires, { unit: 'second' });
        const secondsLeft = parseInt(res.split(' ')[0]) - 10;

        refreshInterval = setInterval(() => {
          dispatch(refreshToken());
        }, secondsLeft * 1000);
      } else {
        dispatch(refreshToken());
      }
    }

    return () => clearInterval(refreshInterval);
  }, [auth.isLoggedIn, auth.data]);

  return (
    <AppContext.Provider
      value={{
        showSpinner,
        setShowSpinner,
        showTransactionDetail,
        setShowTransactionDetail,
        showSendNotification,
        setShowSendNotification,
        showBlockConfirmation,
        setShowBlockConfirmation,
        showAddCar,
        setShowAddCar,
        showConfirmation,
        setShowConfirmation,
        showAddTourSchedule,
        setShowAddTourSchedule,
        toastList,
        setToastList,
        showToast,
        showAddNewGarage,
        setShowAddNewGarage,
        showAddNewCity,
        setShowAddNewCity,
        showRefundDetail,
        setShowRefundDetail,
        showNotificationForm,
        setShowNotificationForm,
        showNotificationTemplate,
        setShowNotificationTemplate,
        showNotificationDetail,
        setShowNotificationDetail,
        // active,
        // setActive,
        showCityDeleteConfirmation,
        setShowCityDeleteConfirmation,
        showDepositDetailModal,
        setShowDepositDetailModal,
        showDepositConfirmation,
        setShowDepositConfirmation,
        showAddDeliveryLocation,
        setShowAddDeliveryLocation,
        showContactUsDetail,
        setShowContactUsDetail,
        showAddAboutUsImage,
        setShowAddAboutUsImage,
        showAddBannerModal,
        setShowAddBannerModal,
        showAddBrandCar,
        setShowAddBrandCar,
        // activeSubMenu,
        // setActiveSubMenu,
        cancelPaymentMessageValue,
        setCancelPaymentMessageValue,
        showCancelPaymentMessage,
        setShowCancelPaymentMessage,
        showAddRolesModal,
        setShowAddRolesModal,
        showAddRolesAssignModal,
        setShowAddRolesAssignModal,
        showAddCategory,
        setShowAddCategory,
        showAddViolationsModal,
        setShowAddViolationsModal,
        showAssignDriver,
        setShowAssignDriver,
        showZonePrice,
        setShowZonePrice,
        showRejectOrderModal,
        setShowRejectOrderModal,
        showAddNewLanguage,
        setShowAddNewLanguage,
        showAddAirportLocationType,
        setShowAddAirportLocationType,
        showAddAirportLocation,
        setShowAddAirportLocation,
        showAdditionalImageModal,
        setShowAdditionalImageModal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider');
  }
  return context;
};
