import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrow } from 'icons/left-arrow.svg';
import ReactDOM from 'react-dom';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkEmptyObject } from 'utils/functionality';
import { getDetailOrder } from 'features/orders/detailOrderSlice';
import WithoutDriverDetail from './without-driver-detail';
import WithDriverDetail from './with-driver-detail';
import PreviewImage from 'components/Modals/PreviewImage';
import { getAllApprovalData, getApprovalDataById, updateApprovalStatus } from 'features/approvals/actions';
import { fetchOrderById, getOrderAddOnsById } from 'features/orders/actions';
import { useAppContext } from 'components/Context/AppContext';
import AirportTransferDetail from './airport-transfer-detail';

const INITIAL_STATE = {
  booking_price: 0,
  order_detail: {
    end_booking_date: '',
    end_booking_time: '',
    rental_delivery_location: '',
    rental_delivery_location_detail: '',
    rental_return_location: '',
    rental_return_location_detail: '',
    start_booking_date: '',
    start_booking_time: '',
    vehicle_id: 0,
    location_id: 0,
    loc_time_id: '',
    landing_time: '',
    flight_number: '',
    booking_zones: [],
  },
  order_type_id: 0,
  deposit: 0,
  over_time_price: 0,
  over_time: 0,
  order_violations: [],
};

const TaskAdminDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderData = useSelector(getDetailOrder);
  const approvalData = useSelector((state) => state.approvals.selected);
  const [oldOrderData, setOldOrderData] = useState({});
  const [newOrderData, setNewOrderData] = useState(INITIAL_STATE);
  const { setShowConfirmation, showToast, setShowSpinner } = useAppContext();
  const [showImage, setShowImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const { transactionKey } = useParams();
  const [searchParams] = useSearchParams();
  const approvalId = searchParams.get('id');

  useEffect(() => {
    if (transactionKey && approvalId) {
      dispatch(getApprovalDataById(approvalId));
      dispatch(fetchOrderById(transactionKey));
      dispatch(getOrderAddOnsById(transactionKey));
    }
  }, [transactionKey, approvalId]);

  useEffect(() => {
    if (checkEmptyObject(orderData) || checkEmptyObject(approvalData)) return;
    setOldOrderData(orderData || INITIAL_STATE);
    setNewOrderData(
      (approvalData.approval_setting.name.includes('delete order')
        ? { ...approvalData, data: orderData }
        : approvalData) || INITIAL_STATE,
    );
  }, [orderData, approvalData]);

  const backHandler = () => {
    navigate(-1);
  };

  const getFileName = (path = '') => path.split('/').pop();

  const imagePreviewHandler = (image) => {
    setShowImage(true);
    setSelectedImage(image);
  };

  const confirmHandler = (status) => {
    const method = approvalData?.approval_setting?.name.includes('update order') ? 'update' : 'penghapusan';
    setShowConfirmation({
      message: `Apakah anda yakin ingin ${
        status === 'processed' ? 'proses' : status === 'approved' ? 'menyetujui' : 'menolak'
      } ${method} data order ini?`,
      show: true,
      onClick: async () => {
        setShowSpinner(true);
        try {
          await dispatch(updateApprovalStatus({ status, id: +approvalId })).unwrap();
          showToast({ type: 'success', message: 'Transaksi Berhasil Disetujui' });
        } catch (err) {
          showToast({ type: 'error', message: 'Transaksi Gagal Disetujui' });
        } finally {
          setShowSpinner(false);
          navigate(-1);
          dispatch(
            getAllApprovalData({
              page: 1,
              limit: 10,
            }),
          );
        }
      },
    });
  };

  return (
    <>
      <div className="task-admin">
        <div className="task-admin-detail__back-btn" onClick={backHandler}>
          <LeftArrow />
          <p>Kembali</p>
        </div>
        {orderData?.order_type_id === 2 ? (
          <AirportTransferDetail
            getFileName={getFileName}
            imagePreviewHandler={imagePreviewHandler}
            newOrderData={newOrderData}
            oldOrderData={oldOrderData}
            orderData={orderData}
            transactionKey={transactionKey}
            confirmHandler={confirmHandler}
          />
        ) : orderData?.order_detail?.without_driver ? (
          <WithoutDriverDetail
            getFileName={getFileName}
            imagePreviewHandler={imagePreviewHandler}
            newOrderData={newOrderData}
            oldOrderData={oldOrderData}
            orderData={orderData}
            transactionKey={transactionKey}
            confirmHandler={confirmHandler}
          />
        ) : (
          <WithDriverDetail
            getFileName={getFileName}
            imagePreviewHandler={imagePreviewHandler}
            newOrderData={newOrderData}
            oldOrderData={oldOrderData}
            orderData={orderData}
            transactionKey={transactionKey}
            confirmHandler={confirmHandler}
          />
        )}
      </div>
      {showImage &&
        ReactDOM.createPortal(
          <PreviewImage image={selectedImage} setShow={() => setShowImage(false)} />,
          document.getElementById('modal'),
        )}
    </>
  );
};

export default TaskAdminDetail;
